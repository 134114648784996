import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import { MDXRenderer } from "gatsby-plugin-mdx"
import { Link, useStaticQuery, graphql } from "gatsby";

import logo from "../images/logo.svg"

export default function IndexPage() {
  const data = useStaticQuery(graphql`
  {
    mdx: mdx(frontmatter: {slug: {eq:"home"}}) {
      body
    }

    meetups: allMeetupEvent(filter: {visibility: {eq: "public" }}) {
        edges {
          node {
            name
            meetupId
            visibility
            local_date
            link
            status
            description
    
          }
        }
      }
    
  }
  `)

  return (
    <Layout>
      <SEO
        keywords={[`meetup`, `networking`, `Germany`, `Women`, `Tech`, `Startups`]}
        title="Home"
      />
      <div className="md:fixed md:w-full p-12">
        <div className="md:flex md:mb-4 ">
          <div className="text-center md:w-1/3 select-none">
            <img src={logo} />
          </div> 
        </div>
      </div>
      
      <div className="md:flex p-8 md:pt-16 md:mb-16">
        <div className="md:w-1/3"></div>
        <div className="md:w-2/3 ml-auto text-3xl md:text-4xl md:px-32">
          gathering together. establishing inspiring connections. advancing and RISE.  
        </div>
      </div>

      <div className="md:flex text-2xl md:text-3xl">
        <div className="md:w-1/4"></div>  
        <div className="md:w-2/3 p-8">
          <div className="leading-snug">
            <MDXRenderer>{data.mdx.body}</MDXRenderer>
          </div>
        </div>
        <div className="md:w-1/12"></div>
      </div>

      <div className="md:flex text-3xl md:text-4xl mb-64">
        <div className="md:w-1/4"></div>  
        <div className="md:w-2/3 p-8">
          <div className="leading-snug">
          via <br/>
            <a href="https://www.meetup.com/WMN-digital/" className="" target="_blank" rel="noopener noreferrer">Meetup</a> /{' '} 
            <a href="https://www.instagram.com/wasmitnetworking/">Instagram</a> / {' '}
            <a href="mailto:contact@wmn.digital">Mail</a> / {' '}
            <Link to="/imprint">Imprint</Link>
          </div>
        </div>
        <div className="md:w-1/12"></div>
      </div>
    </Layout>
  );
}
